import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Privacy Policy For Forex Expert Advisor | Forex EA Policies");
    this.metaService.updateTag( { name:'description',content:"If you have any questions or concerns about our use of your personal information, then please contact us using the contact details provided at the bottom of this Privacy Policy."});
    this.metaService.updateTag( { name:'keywords',content:"privacy policy, privacy statement, privacy and policies for the expert advisor, expert advisor policies, EA, privacy and policy for forex EA, statement of forex expert advisor, automated trading, forex software, automatic trading robot, auto trading software in Sri Lanka"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
