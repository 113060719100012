import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.css']
})
export class RefundComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Refund Policy For Forex Expert Advisor | Forex EA Trading");
    this.metaService.updateTag( { name:'description',content:"If the product has defected and Not Functioning Maximum of Up to 15 days Refund is Applicable. or otherwise No Refund is issued in this case."});
    this.metaService.updateTag( { name:'keywords',content:"refund policy, refund terms and conditions, refund statement, refund conditions for expert advisor, EA, forex EA refund policy, forex expert advisor, automated trading, refund policy for forex software, automatic trading robot, auto trading software in Sri Lanka, forex EA in Sri Lanka, forex trading software in Sri Lanka"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
