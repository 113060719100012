import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Terms and Conditions - Auto Trading Robot");
    this.metaService.updateTag( { name:'description',content:"Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page."});
    this.metaService.updateTag( { name:'keywords',content:"terms and conditions for forex EA, terms and conditions for expert advisor, EA terms and conditions, forex EA terms and conditions, forex expert advisor, terms of automated trading, forex software terms, terms and conditions for automatic trading robot, auto trading software in Sri Lanka, forex EA in Sri Lanka, forex trading software in Sri Lanka"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
