import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Pricing Plans - Auto Trading Robot");
    this.metaService.updateTag( { name:'description',content:"FX Trading website is a software provider company for forex trading. Offering automatic robot setup with expert managed trading accounts."});
    this.metaService.updateTag( { name:'keywords',content:"price of forex EA, expert advisor, EA, cost of forex EA, forex expert advisor, automated trading, forex software, automatic trading robot, auto trading software in Sri Lanka, forex EA in Sri Lanka, forex trading software in Sri Lanka, robot trading software"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
