import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-hosting',
  templateUrl: './web-hosting.component.html',
  styleUrls: ['./web-hosting.component.css']
})
export class WebHostingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
