import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.css']
})
export class GetStartedComponent implements OnInit {

  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;

  siteToken: string;
  siteKey: string;
  remoteip: string;
  istrue: boolean;
  isformerror: boolean;
  dispemailerr: boolean;
  apiResponse: any;
  name: string;
  email: string;
  verifyemail: boolean;
  mainform: boolean;
  successdiv: boolean;
  country: string = "Sri Lanka";
  domain: string = "www.autotradingea.com";
  phone: number;
  city: string;
  bodydf: any;
  viewbutton: boolean;
  viewbutton2: boolean;
  erroresponse: any;
  emailverifyerror: boolean;
  status: any;
  enqid: string;
  inemail: string;
  resparray: any;
  duplicateemail: boolean;

  constructor(private http: HttpClient,private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {
    this.addRecaptchaScript();
    this.getIPAddress();
    this.mainform = false;
    this.isformerror = true;
    this.viewbutton = false;
    this.dispemailerr = true;
    this.emailverifyerror = true;
    this.successdiv = true;
    this.duplicateemail = true;

    this.title.setTitle("Get The Forex Expert Advisor | Forex EA | Expert Advisor");
    this.metaService.updateTag( { name:'description',content:"Our company is a software provider organization for forex expert advisors. Offering automatic forex robot arrangement with the expert advisor (Forex EA) accounts."});
    this.metaService.updateTag( { name:'keywords',content:"get the best forex expert advisor, forex EA, best forex EA, forex expert advisor, automated trading, forex software, automatic trading robot, auto trading software in Sri Lanka, forex EA in Sri Lanka, forex trading software in Sri Lanka, robot trading software"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : '6LcahYEaAAAAAPjw3uS0pV0ypK7o0WSdxVQ-kTDw',
      'callback': (response) => {
          this.siteToken = response;
      }
    });
  }

reloadcaptcha() {
window['grecaptcha'].reset(this.recaptchaElement.nativeElement);
}

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }

    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));

  }

  getIPAddress()  {

    this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.remoteip = res.ip;
    });

  }

  clearErrors(event): any {
    this.isformerror = true;
    this.dispemailerr = true;
  }

  numberOnly(event): boolean {
    this.isformerror = true;
    this.dispemailerr = true;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  alphabetsOnly(event): boolean {
    this.isformerror = true;
    this.dispemailerr = true;
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || charCode == 32){
      return true;
    }
     return false;
  }

  onSubmit(form: NgForm) {
if(form.valid) {
  this.viewbutton = true;
  this.isformerror = true;
  const headers = new HttpHeaders()
 .set('Access-Control-Allow-Origin','*');
 this.http.post('https://staffdesk.algomastersoftware.com/api/client/emailtest',{name: form.value.name, phone: form.value.phone, email: form.value.email, city: form.value.city,siteToken: form.value.siteToken, remoteip: form.value.remoteip, domain: form.value.domain,country: form.value.country},{'headers' : headers}).subscribe(response => {
 console.warn(response);
 this.resparray = response;
 console.warn(response);
 if(this.resparray.status == 'Success'){
   this.mainform = true;
   this.successdiv = false;
   this.dispemailerr = true;
   this.isformerror = true;
 }
 else if (this.resparray.status == 'mailerror'){
   this.mainform = true;
   this.successdiv = true;
   this.emailverifyerror = false;
   this.viewbutton = false;
   this.verifyemail = this.resparray.inemail;
   this.enqid = this.resparray.enqid;
 }
 else if (this.resparray.status == 'validate'){
   window.location.reload();
 } else {
   this.dispemailerr = false;
   this.isformerror = true;
  }
 },(errorResponse: HttpErrorResponse) => {
  // Extract form error messages from API  <------ HERE!!!
  this.reloadcaptcha();
  this.dispemailerr = false;
  this.isformerror = true;
  this.viewbutton = false;
  const errors = [];
  if (errorResponse.error) {
    //errors.push(errorResponse.error.message);
    this.siteToken = "";
    if (errorResponse.error.errors) {
      for (const property in errorResponse.error.errors) {
        if (errorResponse.error.errors.hasOwnProperty(property)) {
          const propertyErrors: Array<string> = errorResponse.error.errors[property];
          propertyErrors.forEach(error => errors.push(error));
          this.istrue = true;
          this.erroresponse = errors;
          this.reloadcaptcha();
        }
      }
    }
  }
  console.log(errors);
});

} else {
  this.isformerror = false;
  this.viewbutton = false;
}
}


onEmailSubmit(form: NgForm) {
  // this.emailverifyerror = false;
  this.viewbutton = true;
  const headers = new HttpHeaders()
 .set('Access-Control-Allow-Origin','*');
  this.http.post('https://staffdesk.algomastersoftware.com/api/client/verify/email',{ email: form.value.verifyemail, enqid: form.value.enqid},{'headers' : headers}).subscribe(response => {

    if(response == 'true'){
      // console.log("true");
      this.emailverifyerror = true;
      this.successdiv = false;
      this.duplicateemail = true;
    }else if(response == 'duplicate'){
      this.duplicateemail = false;
      this.emailverifyerror = false;
      this.viewbutton = false;
    }
    else{
      console.log("false");
      this.emailverifyerror = false;
      this.viewbutton = false;
      this.duplicateemail = true;
    }
  },(errorResponse: HttpErrorResponse) => {
    console.log("error");
  });
}
}
