import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("How Does Auto Trading Robot Work?");
    this.metaService.updateTag( { name:'description',content:"A Forex Auto Trading Robot that works as a fully Automated Forex Trading System that executes both Buy, Sell Trade Automatically & Close the Trade in Profit."});
    this.metaService.updateTag( { name:'keywords',content:"expert advisor features, EA features, features of forex EA, forex expert advisor features, features of automated trading, forex software, automatic trading robot, auto trading software in Sri Lanka, forex EA in Sri Lanka, forex trading software in sri lanka, robot trading software"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
