import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';
declare let $: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {
    $(function() {
      $('.accordion').find('.accordion-title').on('click', function(){
          // Adds Active Class
          $(this).toggleClass('active');
          // Expand or Collapse This Panel
          $(this).next().slideToggle('fast');
          // Hide The Other Panels
          $('.accordion-content').not($(this).next()).slideUp('fast');
          // Removes Active Class From Other Titles
          $('.accordion-title').not($(this)).removeClass('active');
      });
    });

    this.title.setTitle("FAQ - Expert Advisor For Forex Trading  | EA - FAQs");
    this.metaService.updateTag( { name:'description',content:"Whether I need to have knowledge of Forex EA trading? No need for trading knowledge. All trading processes will be taken care of by the bot. The client can check the notifications of the amount rising."});
    this.metaService.updateTag( { name:'keywords',content:"faq, expert advisor faq, EA, forex EA faq, frequently asked questions, forex expert advisor faq, faq for automated trading, forex software faq, automatic trading robot, auto trading software in Sri Lanka, forex EA in Sri Lanka, forex trading software in Sri Lanka, robot trading software"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});
  }

}
