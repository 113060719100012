import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;

  siteToken: string;
  siteKey: string;
  remoteip: string;
  istrue: boolean;
  isformerror: boolean;
  dispemailerr: boolean;
  apiResponse: any;
  name: string;
  email: string;
  country: string = "Singapore";
  domain: string = "www.autotradingauto.com";
  phone: number;
  city: string;
  bodydf: any;
  viewbutton: boolean;
  erroresponse: any;
  constructor(private http: HttpClient,private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {
    this.addRecaptchaScript();
    this.getIPAddress();
    this.istrue = true;
    this.isformerror = true;
    this.viewbutton = false;
    this.dispemailerr = true;

    this.title.setTitle("Contact Us - Auto Trading Robot");
    this.metaService.updateTag( { name:'description',content:"Reach out to us, for additional enquiries about Forex Expert Advisor and its Services. Anything On your Mind. We are here to assist you!"});
    this.metaService.updateTag( { name:'keywords',content:"contact us, reach us, get in touch with us, contact us for the expert advisor, EA, best forex EA, forex expert advisor contact details, automated trading, forex software, automatic trading robot, contact details for auto trading software in Sri Lanka, details for forex EA in Sri Lanka, forex trading software in Sri Lanka"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : '6LcahYEaAAAAAPjw3uS0pV0ypK7o0WSdxVQ-kTDw',
      'callback': (response) => {
          this.siteToken = response;
      }
    });
  }

  reLoadcaptcha() {
window['grecaptcha'].reset(this.recaptchaElement.nativeElement);
}

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }

    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));

  }

  getIPAddress()  {

    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.remoteip = res.ip;
    });

  }

  clearErrors(event): any {
    this.isformerror = true;
    this.dispemailerr = true;
  }

  numberOnly(event): boolean {
    this.isformerror = true;
    this.dispemailerr = true;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 43) {
      return true;
    } else if((charCode > 32 && (charCode < 48 || charCode > 57 ))){
      return false;
    } else {
      return true;
    }

  }

  alphabetsOnly(event): boolean {
    this.isformerror = true;
    this.dispemailerr = true;
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || charCode == 32){
      return true;
    }
     return false;
  }

  onSubmit(form: NgForm) {

if(form.valid) {
  this.viewbutton = true;
  this.isformerror = true;
  const headers = new HttpHeaders()
 .set('Access-Control-Allow-Origin','*');
 this.http.post('https://staffdesk.ftxtradingsoftware.com/api/angular/sites/enquiry-dop',{name: form.value.name, phone: form.value.phone, email: form.value.email, city: form.value.city,siteToken: form.value.siteToken, remoteip: form.value.remoteip, domain: form.value.domain,country: form.value.country},{'headers' : headers}).subscribe(response => {
 console.warn('got the post response',response);
 if(response == 'Success'){
   this.istrue = false;
   this.dispemailerr = true;
   this.isformerror = true;
 }
 else if (response == 'validate'){
   window.location.reload();
 } else {
   this.dispemailerr = false;
   this.isformerror = true;
   this.viewbutton = false;
 }
 },(errorResponse: HttpErrorResponse) => {
  // Extract form error messages from API  <------ HERE!!!
  this.reLoadcaptcha();
  this.siteToken = "";
  const errors = [];
  if (errorResponse.error) {
    //errors.push(errorResponse.error.message);
    this.dispemailerr = false;
    this.isformerror = true;
    this.viewbutton = false;
    if (errorResponse.error.errors) {
      for (const property in errorResponse.error.errors) {
        if (errorResponse.error.errors.hasOwnProperty(property)) {
          const propertyErrors: Array<string> = errorResponse.error.errors[property];
          propertyErrors.forEach(error => errors.push(error));
          this.istrue = true;
          this.erroresponse = errors;
        }
      }
    }
  }
  console.log(errors);
});

} else {
  this.isformerror = false;
}

}
}
